<template>
  <div id="SearchList">
    123456

    <van-search
      v-model="search"
      shape="round"
      background="#23252E"
      placeholder="请输入搜索关键词"
      @input = focusChange
      style="padding:0.15rem;  position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;"
    />

    <p v-if="alwaysVisit.length && !search" class="littlietitle" style="padding-top:0.58rem">经常访问的人</p>
    <div v-if="alwaysVisit.length && !search" class="visit">
      <div @click="toIntroDetail(i.master_id,i.member.name)" v-for="(i,item) in alwaysVisit" :key="item">
        <img v-if="i.user.headimgurl" :src=" Global.changeImg(i.user.headimgurl)" />
        <img v-else  src="../../assets/image/home/image1.png" />
        <span>{{i.member.name}}</span>
      </div>
    </div>
    <div v-if="!search" :class="{'paddingTop':!alwaysVisit.length}">
      <p class="littlietitle" style="background: #16171D;">可能感兴趣的人</p>
      <div class="allList">
        <div class="list" @click="toIntroDetail(i.user.id,i.name)" v-for="(i,item) in maybeInterest" :key="item">
          <div v-if="i.user">
            <img v-if="i.user.headimgurl" :src=" Global.changeImg(i.user.headimgurl)" />
            <img v-else  src="../../assets/image/home/image1.png" />
            <div>
              <p class="first">{{i.name}}</p>
              <p class="second">{{i.company}}{{i.job}}</p>
              <p class="third">
                <span v-if="i.city">{{i.city}}</span>
                <span v-if="i.industry == 1" >保险</span>
                <span v-if="i.industry == 2" >创业</span>
                <span v-if="i.industry == 3" >互联网</span>
                <span v-if="i.industry == 4" >观察者</span>
                <span v-if="i.industry == 5" >投资</span>
                <span v-if="i.industry == 6" >媒体</span>
              </p>
            </div>
          </div>
          <span v-if="i.user && name != 'findConnections'" @click.stop="toHello(i.user.id)">打个招呼</span>
        </div>
      </div>
    </div>
    <div v-if="search" style="padding-top:0.58rem">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="allList"
      >
        <div class="list" @click="toIntroDetail(i.user.id,i.name)" v-for="(i,item) in getDocList" :key="item">
          <div v-if="i.user">
             <img v-if="i.user.headimgurl" :src=" Global.changeImg(i.user.headimgurl)" />
            <img v-else  src="../../assets/image/home/image1.png" />
            <div>
              <p class="first">{{i.name}}</p>
              <p class="second">{{i.company}}{{i.job}}</p>
              <p class="third">
                <span v-if="i.city">{{i.city}}</span>
                <span v-if="i.industry == 1" >保险</span>
                <span v-if="i.industry == 2" >创业</span>
                <span v-if="i.industry == 3" >互联网</span>
                <span v-if="i.industry == 4" >观察者</span>
                <span v-if="i.industry == 5" >投资</span>
                <span v-if="i.industry == 6" >媒体</span>
              </p>
            </div>
          </div>
          <span v-if="i.user && name != 'findConnections'" @click.stop="toHello(i.user.id)">打个招呼</span>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchList",
  data(){
    return{
      alwaysVisit:'',//经常访问
      maybeInterest:'',
      search:'',//搜索关键词
      getDocList:'',//搜索列表
      next_page_url:'',
      loading:false,
      finished:false,
      name:''
    }
  },
  mounted() {
    this.name = this.$route.name
    // 经常访问
    this.axios({
      method: 'GET',
      url: '/master/alwaysVisit',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.alwaysVisit = res.data.data;
      }
    })
    // 可能感兴趣的人
    this.axios({
      method: 'GET',
      url: '/master/maybeInterest',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.maybeInterest = res.data.data;
      }
    })
  },
  methods:{
    focusChange(){
      window.scrollTo(0,0)
      var url;
      if(this.name == 'findConnections'){
        url = '/quiz/searchMaster?search='+this.search
      }else{
        url = '/master/searchMaster?search='+this.search
      }
      this.axios({
        method: 'GET',
        url:url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            console.log(res.data.data)
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        var url;
        if(this.search){
          url = this.next_page_url+'&search='+this.search
        }
        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    // 跳转大咖详情
    toIntroDetail(id,name){
       if(this.name != 'findConnections'){
        this.axios({
          method: 'PATCH',
          url: '/master/alwaysVisitAdd',
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
          data:{
            masterId:id
          }
        }).then((res) => {
          if(res.data.code == 0){
            this.$router.push({name:'Introduction',query: {id:id}})
          }
        })
      }else{
        this.$router.push({name:'Question',query: {id:id,name:name}})
      }
    },
    toHello(id){
      this.axios({
        method: 'POST',
        url: '/recognition/greetMaster',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast(res.data.data.msg)          
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
#SearchList {
  .paddingTop{
    padding-top:0.58rem
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    padding-left: 0.15rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.1rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  .visit{
    overflow-x: scroll;
    padding-left: 0.15rem;
    white-space: nowrap;
    background: #23252E;
    >div{
      padding: 0.15rem 0;
      margin-right: 0.2rem;
      text-align: center;
      display: inline-block;
      >img{
        width: 0.55rem;
        height: 0.55rem;
        border-radius: 50%;
        margin: 0 auto;
      }
      >span{
        color: #F6F7FD;
        font-size: 0.14rem;
        margin-top: 0.06rem;
        display: inline-block;
      }
    }
  }
  .littlietitle{
    color: #989AB1;
    height: 0.4rem;
    line-height: 0.4rem;
    padding: 0 0.15rem;
  }
  .allList{
    background: #23252E;
    .list:last-child{
      border-bottom: none;
    }
  }
  .list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .1px solid #5C5D6E61;
    margin: 0 0.15rem;
    padding: 0.13rem 0;
    >div{
      display: flex;
      align-items: center;
      flex: 1;
      padding-right: 0.1rem;
      >img{
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
      }
      >div{
        padding: 0 0 0 0.15rem;
        position: relative;
        flex:1;
        .first{
          font-size: 0.16rem;
          color: #F6F7FD;
        }
        .third{
          display: flex;
          flex-wrap: wrap;
          >span{
            padding: 0 0.05rem;
            color: #9091A3;
            font-size: 0.12rem;
            border-radius: 0.02rem;
            background: #3D404F;
            margin: 0 0.05rem 0.05rem 0;
          }
        }
        .second{
          margin: 0.03rem 0 0.03rem 0;
          color: #989AB1;
          display: flex;
          font-size: 0.14rem;
          >span{
            display: flex;
            margin-left: 0.1rem;
            color: #989AB1;
          }
        }
      }
    }
    >span{
      background: #3890FF;
      color: #F6F7FD;
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      font-size: 0.12rem;
    }
  }

 
  >div{
    background: #23252E;
  }
}
</style>